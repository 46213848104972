:root, [data-bs-theme=light] {
  --bs-primary: #a79fec;
  --bs-primary-rgb: 167,159,236;
  --bs-primary-text-emphasis: #43405E;
  --bs-primary-bg-subtle: #EDECFB;
  --bs-primary-border-subtle: #DCD9F7;
  --bs-secondary: #d8e474;
  --bs-secondary-rgb: 216,228,116;
  --bs-secondary-text-emphasis: #565B2E;
  --bs-secondary-bg-subtle: #F7FAE3;
  --bs-secondary-border-subtle: #EFF4C7;
  --bs-success: #adcb00;
  --bs-success-rgb: 173,203,0;
  --bs-success-text-emphasis: #455100;
  --bs-success-bg-subtle: #EFF5CC;
  --bs-success-border-subtle: #DEEA99;
  --bs-warning: #eac646;
  --bs-warning-rgb: 234,198,70;
  --bs-warning-text-emphasis: #5E4F1C;
  --bs-warning-bg-subtle: #FBF4DA;
  --bs-warning-border-subtle: #F7E8B5;
  --bs-body-color: #22260d;
  --bs-body-color-rgb: 34,38,13;
  --bs-secondary-color: rgba(34,38,13,0.75);
  --bs-secondary-color-rgb: 34,38,13,0.75;
  --bs-tertiary-color: rgba(34,38,13,0.5);
  --bs-tertiary-color-rgb: 34,38,13,0.5;
  --bs-body-bg: #e4ec9f;
  --bs-body-bg-rgb: 228,236,159;
  --bs-body-font-family: 'Bricolage Grotesque', sans-serif;
}

#root {
  position: relative;
}

.alert-primary {
  --bs-alert-color:  #a79fec;
  --bs-alert-bg:  #e0ddf4;
  --bs-alert-border-color: #a79fec;
}

.alert-success {
  --bs-alert-color: #96af04;
  --bs-alert-bg: #ecf1ce;
  --bs-alert-border-color: #adcb00;
}

.alert-warning {
  --bs-alert-color: #eac646;
  --bs-alert-bg: #fff4ce;
  --bs-alert-border-color: #eac646;
}

.btn-primary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #a79fec;
  --bs-btn-border-color: #a79fec;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #B4ADEF;
  --bs-btn-hover-border-color: #B0A9EE;
  --bs-btn-focus-shadow-rgb: 25,24,35;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #B9B2F0;
  --bs-btn-active-border-color: #B0A9EE;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a79fec;
  --bs-btn-disabled-border-color: #a79fec;
}


.btn-white {
  --bs-btn-color: #a79fec;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #a79fec;
  --bs-btn-hover-border-color: #a79fec;
  --bs-btn-focus-shadow-rgb: 25,24,35;
  --bs-btn-active-color: #a79fec;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a79fec;
  --bs-btn-disabled-border-color: #a79fec;
}

.btn-outline-primary {
  --bs-btn-color: #a79fec;
  --bs-btn-border-color: #a79fec;
  --bs-btn-focus-shadow-rgb: 167,159,236;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a79fec;
  --bs-btn-hover-border-color: #a79fec;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a79fec;
  --bs-btn-active-border-color: #a79fec;
  --bs-btn-disabled-color: #a79fec;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a79fec;
}

.btn-secondary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d8e474;
  --bs-btn-border-color: #d8e474;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #DEE889;
  --bs-btn-hover-border-color: #DCE782;
  --bs-btn-focus-shadow-rgb: 32,34,17;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #E0E990;
  --bs-btn-active-border-color: #DCE782;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d8e474;
  --bs-btn-disabled-border-color: #d8e474;
}

.btn-outline-secondary {
  --bs-btn-color: #d8e474;
  --bs-btn-border-color: #d8e474;
  --bs-btn-focus-shadow-rgb: 216,228,116;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d8e474;
  --bs-btn-hover-border-color: #d8e474;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d8e474;
  --bs-btn-active-border-color: #d8e474;
  --bs-btn-disabled-color: #d8e474;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d8e474;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #adcb00;
  --bs-btn-border-color: #adcb00;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #B9D326;
  --bs-btn-hover-border-color: #B5D019;
  --bs-btn-focus-shadow-rgb: 26,30,0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #BDD533;
  --bs-btn-active-border-color: #B5D019;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #adcb00;
  --bs-btn-disabled-border-color: #adcb00;
}

.btn-outline-success {
  --bs-btn-color: #adcb00;
  --bs-btn-border-color: #adcb00;
  --bs-btn-focus-shadow-rgb: 173,203,0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #adcb00;
  --bs-btn-hover-border-color: #adcb00;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #adcb00;
  --bs-btn-active-border-color: #adcb00;
  --bs-btn-disabled-color: #adcb00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #adcb00;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eac646;
  --bs-btn-border-color: #eac646;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #EDCF62;
  --bs-btn-hover-border-color: #ECCC59;
  --bs-btn-focus-shadow-rgb: 35,30,11;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #EED16B;
  --bs-btn-active-border-color: #ECCC59;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eac646;
  --bs-btn-disabled-border-color: #eac646;
}

.btn-outline-warning {
  --bs-btn-color: #eac646;
  --bs-btn-border-color: #eac646;
  --bs-btn-focus-shadow-rgb: 234,198,70;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eac646;
  --bs-btn-hover-border-color: #eac646;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eac646;
  --bs-btn-active-border-color: #eac646;
  --bs-btn-disabled-color: #eac646;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eac646;
}

[data-bs-theme=dark] {
  --bs-primary: #afc94f;
  --bs-primary-rgb: 175,201,79;
  --bs-primary-text-emphasis: #CFDF95;
  --bs-primary-bg-subtle: #232810;
  --bs-primary-border-subtle: #69792F;
  --bs-secondary: #485412;
  --bs-secondary-rgb: 72,84,18;
  --bs-secondary-text-emphasis: #919871;
  --bs-secondary-bg-subtle: #0E1104;
  --bs-secondary-border-subtle: #2B320B;
  --bs-info: #676104;
  --bs-info-rgb: 103,97,4;
  --bs-info-text-emphasis: #A4A068;
  --bs-info-bg-subtle: #151301;
  --bs-info-border-subtle: #3E3A02;
  --bs-body-color: #eef2d9;
  --bs-body-color-rgb: 238,242,217;
  --bs-secondary-color: rgba(238,242,217,0.75);
  --bs-secondary-color-rgb: 238,242,217,0.75;
  --bs-tertiary-color: rgba(238,242,217,0.5);
  --bs-tertiary-color-rgb: 238,242,217,0.5;
  --bs-body-bg: #284148;
  --bs-body-bg-rgb: 40,65,72;
}

[data-bs-theme=dark] .btn-primary, .btn-primary[data-bs-theme=dark] {
  --bs-btn-color: #000000;
  --bs-btn-bg: #afc94f;
  --bs-btn-border-color: #afc94f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #BBD169;
  --bs-btn-hover-border-color: #B7CE61;
  --bs-btn-focus-shadow-rgb: 26,30,12;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #BFD472;
  --bs-btn-active-border-color: #B7CE61;
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #afc94f;
  --bs-btn-disabled-border-color: #afc94f;
}

[data-bs-theme=dark] .btn-outline-primary, .btn-outline-primary[data-bs-theme=dark] {
  --bs-btn-color: #afc94f;
  --bs-btn-border-color: #afc94f;
  --bs-btn-focus-shadow-rgb: 175,201,79;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #afc94f;
  --bs-btn-hover-border-color: #afc94f;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #afc94f;
  --bs-btn-active-border-color: #afc94f;
  --bs-btn-disabled-color: #afc94f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #afc94f;
}

[data-bs-theme=dark] .btn-secondary, .btn-secondary[data-bs-theme=dark] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #485412;
  --bs-btn-border-color: #485412;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3D470F;
  --bs-btn-hover-border-color: #3A430E;
  --bs-btn-focus-shadow-rgb: 228,229,219;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3A430E;
  --bs-btn-active-border-color: #363F0E;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #485412;
  --bs-btn-disabled-border-color: #485412;
}

[data-bs-theme=dark] .btn-outline-secondary, .btn-outline-secondary[data-bs-theme=dark] {
  --bs-btn-color: #485412;
  --bs-btn-border-color: #485412;
  --bs-btn-focus-shadow-rgb: 72,84,18;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #485412;
  --bs-btn-hover-border-color: #485412;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #485412;
  --bs-btn-active-border-color: #485412;
  --bs-btn-disabled-color: #485412;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #485412;
}

[data-bs-theme=dark] .btn-info, .btn-info[data-bs-theme=dark] {
  --bs-btn-color: #fff;
  --bs-btn-bg: #676104;
  --bs-btn-border-color: #676104;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #585203;
  --bs-btn-hover-border-color: #524E03;
  --bs-btn-focus-shadow-rgb: 232,231,217;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #524E03;
  --bs-btn-active-border-color: #4D4903;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #676104;
  --bs-btn-disabled-border-color: #676104;
}

[data-bs-theme=dark] .btn-outline-info, .btn-outline-info[data-bs-theme=dark] {
  --bs-btn-color: #676104;
  --bs-btn-border-color: #676104;
  --bs-btn-focus-shadow-rgb: 103,97,4;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #676104;
  --bs-btn-hover-border-color: #676104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #676104;
  --bs-btn-active-border-color: #676104;
  --bs-btn-disabled-color: #676104;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #676104;
}

.fit-cover {
  object-fit: cover;
}

.bs-icon {
  --bs-icon-size: .75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon-xs {
  --bs-icon-size: 1rem;
  width: calc(var(--bs-icon-size) * 1.5);
  height: calc(var(--bs-icon-size) * 1.5);
}

.bs-icon-sm {
  --bs-icon-size: 1rem;
}

.bs-icon-md {
  --bs-icon-size: 1.5rem;
}

.bs-icon-lg {
  --bs-icon-size: 2rem;
}

.bs-icon-xl {
  --bs-icon-size: 2.5rem;
}

.bs-icon.bs-icon-primary {
  color: var(--bs-white);
  background: var(--bs-primary);
}

.bs-icon.bs-icon-primary-light {
  color: var(--bs-primary);
  background: rgba(var(--bs-primary-rgb), .2);
}

.bs-icon.bs-icon-semi-white {
  color: var(--bs-primary);
  background: rgba(255, 255, 255, .5);
}

.bs-icon.bs-icon-rounded {
  border-radius: .5rem;
}

.bs-icon.bs-icon-circle {
  border-radius: 50%;
}

.br-10 {
  border-radius: 10px;
}

.highlight {
  position: relative;
  z-index: 1;
}

.highlight::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  right: 0;
  height: 40%;
  background-color: var(--bs-primary);
  border-radius: 20px;
}

.video-container::before {
  content: "";
  display: block;
  padding-top: 52.08%;
}

.video-container {
  position: relative;
  width: 75%;
}

.badge-lobitos {
  background: rgb(238,201,79);
}

.badge-lobitos .badge-tooltip {
  background: rgb(238,201,79);
}

.badge-exploradores {
  background: rgb(91,179,138);
}

.badge-exploradores .badge-tooltip {
  background: rgb(91,179,138);
}

.badge-pioneiros {
  background: rgb(31,93,119);
}

.badge-pioneiros .badge-tooltip {
  background: rgb(31,93,119);
}

.badge-caminheiros {
  background: rgb(224,73,74);
}

.badge-caminheiros .badge-tooltip {
  background: rgb(224,73,74);
}

.badge-seccao {
  position: absolute;
  height: 25pt;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 0 10px rgb(51,48,48);
  transition: all 0.3s;
  transform-origin: 50% 50%;
  text-align: center;
  font-size: 8pt;
}

.badge-seccao-open {
  height: 50pt;
  padding: 10px;
  border-radius: 5px;
  font-size: 12pt;
  z-index: 999;
}

.badge-text {
  color: rgb(255,255,255);
}

.badge-multiple {
  position: absolute;
  background-color: #ffffff;
  height: 25pt;
  width: 25pt;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(51,48,48);
  transition: all 0.3s;
}

.small-circle {
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s;
  box-shadow: 0 0 3px rgb(51,48,48);
}

.landmark {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  font-size: 20px;
}

.button-container {
  display: flex;
}

.button-container button {
  flex: 1;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
}

.btn-clicked {
  filter: contrast(65%);
}

.btn-clicked img {
  filter: contrast(0%);
}

.badge-tooltip {
  visibility: hidden;
  padding: 0px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 130%;
  color: rgb(255,255,255);
  font-size: 7pt;
}

.landmark-tooltip {
  /*visibility: hidden;*/
  padding: 0px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 10pt;
  min-width: 200px;
}

.landmark-right {
  left: 130%;
  text-align: left;
}

.landmark-left {
  right: 130%;
  text-align: right;
}

#navbar {
  background: var(--bs-primary);
}

#logo-navbar {
  height: 60px;
}

.nav-link-white {
  padding: 0.6rem;
  color: white;
}

.nav-link-white.active {
  color: var(--bs-primary) !important;
}

.nav-username-button {
  resize: none;
}

.text-dark {
  color: rgb(0,0,0);
}

.pb-20 {
  padding-bottom: 20px;
}

.fs-12 {
  font-size: 12px;
}

.pos-rel {
  position: relative;
}

#container-map{
  overflow: hidden;
  height: 100vh;
  position: relative;
  min-width: 100%;
  padding: 0px;
}

#map{
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-width: 100%;
}

#heading-white{
  position: absolute;
  top: 15vh;
  color: white;
  margin-bottom: 50px;
  width: 100%;
}

.landmark-tooltip span {
  color: rgb(255, 255, 255); 
  background-color: rgb(167, 159, 236);
}

.h-35pt {
  height:35pt;
}

.h-45pt {
  height:45pt;
}

.h-120 {
  height: 120px !important;
}

.w-40pt {
  width:40pt;
}

.w-30pt {
  width:30pt
}

.gradient-bg {
  background: linear-gradient(to bottom, #adcb00, transparent);
}

#intro{
  width: 75vw;
  color: black;
  padding: 3rem;
  background: #ffffff85;
  font-size: 14pt;
  box-shadow: 0 0 10px rgb(0 0 0 / 13%);
  border-radius: 20px;
}

.not-ready{
  left:-100px;
  top:-100px;
}

.section-button-group{
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  backdrop-filter: opacity(1);
}

.full-opacity{
  opacity: 1;
}

.btn-section-img{
  width: 32px;
  height: 32px;
}

.btn-group-end {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.btn-group-start {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btn-group-middle {
  border-radius: 0px;
}

.intro-video {
  border: 10px solid white;
  top: 0;
  left: 0;
}

.border-button {
  border-radius: 20px;
}

.color-yellow {
  color: rgb(238, 201, 79);
}

.position-absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.right-0{
  right: 0%;
}

.right-5{
  right: 50px;
}

.right-20{
  right: 20%;
}

.primary-color{
  color: var(--bs-primary);
}

.mt-150{
  margin-top: 150px;
}

.mt-10pct{
  margin-top: 10%;
}

.mt-5pct{
  margin-top: 5%;
}

.text-white{
  color: white;
}

.mr-10{
  margin-right: 10px;
}

.accordion-desafios {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #ffffff78;
  --bs-accordion-border-color: #ffffff;
  --bs-accordion-btn-color: #ffffff;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe00;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #ffffff;
  --bs-accordion-border-width: 2px;
}

.flexbox-end {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.accordion-body a:last-child .challenge {
  margin-bottom: 0;
}

.challenge {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.challenge-open {
  background-color: rgba(255,255,255,0.6);
  color: black;
}

.challenge-submitted {
  background-color: var(--bs-primary);
  color: white;
}

.challenge-accepted {
  background-color: var(--bs-success);
  color: white;
}

.challenge-rejected {
  background-color: var(--bs-warning);
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-body-bg); /* Adjust the opacity and color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Adjust the z-index as needed to make sure it's above other elements */
  transition: all 1s;
}

.overlay-content {
  text-align: center;
  color: #000000; /* Text color */
  padding: 20px;
}

.overflow-break {
  overflow-wrap: break-word;
}

.word-break {
  word-wrap: break-word;
}

#background-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: -1;
}

.text-body-light {
  color: #7f7f7f;
}

.mountain-size{
  height: 237px;
  width: 654px;
}

.fs-5-custom{
  font-size: 1.25rem!important;
}

.fs-6-custom{
  font-size: 1rem!important;
}

.pl-10{
  padding-left: 10px;
}

.w-2rem {
  width: 2rem;
}

.w-3rem {
  width: 3rem;
}

.map-point {
  background-color:white;
  border-radius:10px;
  width:5px;
  height:5px;
  border:1px solid black;
}

.max-height-50 {
  max-height: 50vh;
}

.overflow-y-auto {
  overflow-y: auto;
}

#modal_users_body hr:last-child {
  display: none;
}

.thumbnail {
  height: 150px;
}

@media (max-width:490px){
  .mountain-size{
    height: 119px;
    width: 327px;
  }

  .fs-5-custom{
    font-size: 1rem!important;
  }
  
  .fs-6-custom{
    font-size: 0.75rem!important;
  }
}

@media (min-width:490px) and (max-width:767px){
  .mountain-size{
    height: 156px;
    width: 430px;
  }

  .fs-5-custom{
    font-size: 1rem!important;
  }
  
  .fs-6-custom{
    font-size: 0.75rem!important;
  }
}

@media (max-width:767px){
  .btn-section-img{
    width: 20px;
    height: 20px;
  }

  .btn-group-start, .btn-group-middle, .btn-group-end {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
  }

  #logo-navbar {
    height:40px;
  }

  #intro{
    width: 90vw;
    color: black;
    padding: 3rem;
    background: #ffffff85;
    font-size: 12pt;
    box-shadow: 0 0 10px rgb(0 0 0 / 13%);
    border-radius: 20px;
  }

  .landmark-right {
    top:80%;
    left: -130%;
    text-align: center;
  }
  
  .landmark-left {
    top:75%;
    right: -140%;
    text-align: center;
  }

  .landmark-tooltip {
    /*visibility: hidden;*/
    padding: 0px 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    font-size: 8pt;
    min-width: 150px;
  }

  .badge-seccao {
    height: 17pt;
    border: 1px solid #ffffff;
    font-size: 5pt;
  }

  .badge-multiple {
    height: 17pt;
    width: 17pt;
  }

  .small-circle {
    position: absolute;
    border-radius: 50%;
    transition: all 0.3s;
    box-shadow: 0 0 3px rgb(51,48,48);
  }
}

@media (hover: hover) {
  .badge-multiple:hover {
    background-color: #ffffff;
    transform: scale(2);
    border-radius: 50%;
    z-index: 999;
    cursor: pointer;
  }

  .badge-multiple:hover .small-circle {
    opacity: 0;
  }

  .badge-multiple:hover::after{
    content: 'ver mais';
    text-align: center;
    color: rgb(114, 114, 114);
    font-size: 5pt;
  }

  .landmark:hover .landmark-tooltip {
    visibility: visible;
  }

  .nav-link-white:hover {
    color: var(--bs-primary);
  }

  .challenge:hover {
    opacity: 0.75;
    border: 1px solid white;
  }

  .map-point:hover {
    transform: scale(2);
  }
}